import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
import WebFont from 'webfontloader';
import './css/global.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
WebFont.load({
  google: {
    families: [ 'Nunito:300,400,500,600,700,800,900']
    // families: ['Noto Sans SC Sliced',
    //                 'Quicksand:300,400,500,600,700,800,900'],
    //             urls: [
    //                 'https://fonts.googleapis.com/css2?family=Quicksand&family=Varela+Round&display=swap',
    //                 'https://fonts.googleapis.com/earlyaccess/notosansscsliced.css'
    //             ]
  }
});
ReactDOM.render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>,
  document.getElementById('root')
);

