import React,{Suspense} from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
const App = React.lazy(() => import('./App'));
const Signup = React.lazy(() => import('./auth/Signup'));
const SignupEmailSent = React.lazy(() => import('./auth/SignupEmailSent'));
const Signin = React.lazy(() => import('./auth/Signin'));
const Activate = React.lazy(() => import('./auth/Activate'));
const Private = React.lazy(() => import('./core/Private'));
const PrivateRoute = React.lazy(() => import('./auth/PrivateRoute'));
const AdminRoute = React.lazy(() => import('./auth/AdminRoute'));
const Admin = React.lazy(() => import('./core/Admin'));
const Forgot = React.lazy(() => import('./auth/Forgot'));
const Reset = React.lazy(() => import('./auth/Reset'));
const AddCategory = React.lazy(() => import('./admin/AddCategory'));
const CarScraper = React.lazy(() => import('./admin/CarScraper'));
const AddAd = React.lazy(() => import('./user/addAd'));
const AddJobAd = React.lazy(() => import('./user/addJobAd'));
const NotFound = React.lazy(() => import('./core/NotFound'));
const UpdateUser = React.lazy(() => import('./core/UpdateUser'));


const AdChoiceComponent = React.lazy(() => import('./core/AdChoiceComponent'));
const QuerySearchComponent = React.lazy(() => import('./ads/QuerySearchComponent'));
const VehiclesMainComponent = React.lazy(() => import('./ads/vehicles/VehiclesMainComponent'));
const CarsMainComponent = React.lazy(() => import('./ads/vehicles/CarsMainComponent'));
const BikessMainComponent = React.lazy(() => import('./ads/vehicles/BikesMainComponent'));
const ThreeWheelMainComponent = React.lazy(() => import('./ads/vehicles/ThreeWheelMainComponent'));
const VansMainComponent = React.lazy(() => import('./ads/vehicles/VansMainComponent'));
const BusMainComponent = React.lazy(() => import('./ads/vehicles/BusMainComponent'));

const PropertiesMainComponent = React.lazy(() => import('./ads/properties/PropertiesMainComponent'));
const LandForSaleMainComponent = React.lazy(() => import('./ads/properties/LandForSaleMainComponent'));
const LandForRentMainComponent = React.lazy(() => import('./ads/properties/LandForRentMainComponent'));
const HouseForSaleMainComponent = React.lazy(() => import('./ads/properties/HouseForSaleMainComponent'));
const HouseForRentMainComponent = React.lazy(() => import('./ads/properties/HouseForRentMainComponent'));
const ApartmentForSaleMainComponent = React.lazy(() => import('./ads/properties/ApartmentForSaleMainComponent'));
const ApartmentForRentMainComponent = React.lazy(() => import('./ads/properties/ApartmentForRentMainComponent'));
const CommercialPropertyForSaleMainComponent = React.lazy(() => import('./ads/properties/CommercialPropertyForSale'));
const CommercialPropertyForRentMainComponent = React.lazy(() => import('./ads/properties/CommercialPropertyForRent'));
const RoomAnnexForRentMainComponent = React.lazy(() => import('./ads/properties/RoomAnnexForRentMainComponent'));
const HolidayPropertyForRentMainComponent = React.lazy(() => import('./ads/properties/HolidayPropertyForRentMainComponent'));


const BicycleMainComponent = React.lazy(() => import('./ads/vehicles/BicycleMainComponent'));
const WatercraftMainComponent = React.lazy(() => import('./ads/vehicles/WatercraftMainComponent'));
const VehicleRentalsMainComponent = React.lazy(() => import('./ads/vehicles/VehicleRentalsMainComponent'));
const WeddingCarsMainComponent = React.lazy(() => import('./ads/vehicles/WedingCarsMainComponent'));
const SparePartsMainComponent = React.lazy(() => import('./ads/spareparts/SparePartsMainComponent'));
const CarSparePartsMainComponent = React.lazy(() => import('./ads/spareparts/CarSparePartsMainComponent'));
const BikeSparePartMainComponent = React.lazy(() => import('./ads/spareparts/BikeSparePartsMainComponent'));
const VanSparePartsMainComponent = React.lazy(() => import('./ads/spareparts/VanSparePartsMainComponent'));
const ThreeWheelSparePartMainComponent = React.lazy(() => import('./ads/spareparts/ThreeWheelSparePartsMainComponent'));

const MobilesMainComponent = React.lazy(() => import('./ads/mobile/mobilesMainComponent'));
const TabsMainComponent = React.lazy(() => import('./ads/mobile/TabsMainComponent'));
const WearablesMainComponent = React.lazy(() => import('./ads/mobile/WearablesMainComponent'));
const MobileTabsWearablesMainComponent = React.lazy(() => import('./ads/mobile/MobilesTabsWearablesMainComponent'));
const MobileAccessoriesMainComponent = React.lazy(() => import('./ads/mobile/MobileAccessoriesMainComponent'));

const GemAndJwelleryMainComponent = React.lazy(() => import('./ads/gemsAndJwellery/GemAndJwelleryMainComponent'));
const GemsMainComponent = React.lazy(() => import('./ads/gemsAndJwellery/GemsMainComponent'));
const WomenJewelleryMainComponent = React.lazy(() => import('./ads/gemsAndJwellery/WomenJewelleryMainComponent'));
const MenJewelleryMainComponent = React.lazy(() => import('./ads/gemsAndJwellery/MenJewelleryMainComponent'));

const MusicInstrumentsMainComponent = React.lazy(() => import('./ads/musical_instruments/MusicInstrumentsMainComponent'));
const GuitarMainComponent = React.lazy(() => import('./ads/musical_instruments/GuitarMainComponent'));
const GuitarAccessoriesMainComponent = React.lazy(() => import('./ads/musical_instruments/GuitarAccessoriesMainComponent'));
const DrumMainComponent = React.lazy(() => import('./ads/musical_instruments/DrumsMainComponent'));
const UkuleleMainComponent = React.lazy(() => import('./ads/musical_instruments/UkuleleMainComponent'));
const MandolinMainComponent = React.lazy(() => import('./ads/musical_instruments/MandolinMainComponent'));
const IndianMainComponent = React.lazy(() => import('./ads/musical_instruments/IndianMainComponent'));
const ViolinMainComponent = React.lazy(() => import('./ads/musical_instruments/ViolinMainComponent'));
const WindMainComponent = React.lazy(() => import('./ads/musical_instruments/WindMainComponent'));
const AmpMainComponent = React.lazy(() => import('./ads/musical_instruments/AmpmainComponent'));
const EffectMainComponent = React.lazy(() => import('./ads/musical_instruments/EffectMainComponent'));
const KeyboardMainComponent = React.lazy(() => import('./ads/musical_instruments/KeyboardMainComponent'));
const StudioMainComponent = React.lazy(() => import('./ads/musical_instruments/StudioMainComponent'));
const PALiveSoundMainComponent = React.lazy(() => import('./ads/musical_instruments/PALiveSoundMainComponent'));
const DjMainComponent = React.lazy(() => import('./ads/musical_instruments/DjMainComponent'));

const HomeGardenMainComponent = React.lazy(() => import('./ads/homeGarden/HomeGardenMainComponent'));
const FurnitureMainComponent = React.lazy(() => import('./ads/homeGarden/FurnitureMainComponent'));
const GardenMainComponent = React.lazy(() => import('./ads/homeGarden/GardenMainComponent'));
const HomeDecorMainComponent = React.lazy(() => import('./ads/homeGarden/HomeDecorMainComponent'));
const KitchenMainComponent = React.lazy(() => import('./ads/homeGarden/KitchenMainComponent'));
const LampsMainComponent = React.lazy(() => import('./ads/homeGarden/LampsMainComponent'));
const HouseHoldCleaningMainComponent = React.lazy(() => import('./ads/homeGarden/HouseHoldCleaningMainComponent'));
const HomeOrganizationMainComponent = React.lazy(() => import('./ads/homeGarden/HomeOrganizationMainComponent'));
const BeddingMainComponent = React.lazy(() => import('./ads/homeGarden/BeddingMainComponent'));
const RugsMainComponent = React.lazy(() => import('./ads/homeGarden/RugsMainComponent'));

const EarthMovingMainComponent = React.lazy(() => import('./ads/industryMachinery/EarthMovingMainComponent'));
const IndustryMachineryMainComponent = React.lazy(() => import('./ads/industryMachinery/IndustryMachinerymainComponent'));
const MetalindustryMainComponent = React.lazy(() => import('./ads/industryMachinery/MetalIndustryMainComponent'));
const PowertoolMainComponent = React.lazy(() => import('./ads/industryMachinery/PowertoolsMainComponent'));
const ElectricindustryMainComponent = React.lazy(() => import('./ads/industryMachinery/ElectricindustryMainComponent'));
const IndustrialAutomationMainComponent = React.lazy(() => import('./ads/industryMachinery/IndustrialAutomationMainComponent'));
const HealthcareMainComponent = React.lazy(() => import('./ads/industryMachinery/HealthcareMainComponent'));
const IndustryCoolingHeatingMainComponent = React.lazy(() => import('./ads/industryMachinery/IndustryCoolingHeatingMainComponent'));
const PowerGenerationMainComponent = React.lazy(() => import('./ads/industryMachinery/PowerGenerationMainComponent'));
const PrintingIndustryMainComponent = React.lazy(() => import('./ads/industryMachinery/PrintingIndustryMainComponent'));
const MaterialHandlingMainComponent = React.lazy(() => import('./ads/industryMachinery/MaterialHandlingMainComponent'));
const FoodProcessingMainComponent = React.lazy(() => import('./ads/industryMachinery/FoodProcessingMainComponent'));
const TextileIndustryMainComponent = React.lazy(() => import('./ads/industryMachinery/TextileIndustryMainComponent'));

const SportsFitnessMainComponent = React.lazy(() => import('./ads/sports_fitness/SportsFitnessMainCoponent'));
const FitnessGymMainComponent = React.lazy(() => import('./ads/sports_fitness/FitnessGymMainComponent'));
const SupplementMainComponent = React.lazy(() => import('./ads/sports_fitness/SupplementMainComponent'));
const TeamSportsMainComponent = React.lazy(() => import('./ads/sports_fitness/TeamSportsMainComponent'));
const IndoorSportsMainComponent = React.lazy(() => import('./ads/sports_fitness/IndoorSportsMainComponent'));
const TennisSportsMainComponent = React.lazy(() => import('./ads/sports_fitness/TennisSportsMainComponent'));
const CyclingMainComponent = React.lazy(() => import('./ads/sports_fitness/CyclingMainComponent'));
const UrbanSportsMainComponent = React.lazy(() => import('./ads/sports_fitness/UrbanSportsMainComponent'));
const CampingMainComponent = React.lazy(() => import('./ads/sports_fitness/CampingMainComponent'));

const FashionAndBeautyMainComponent = React.lazy(() => import('./ads/fashionAndBeauty/FashionAndBeautyMainComponent'));
const WomenClothingMainComponent = React.lazy(() => import('./ads/fashionAndBeauty/WomenClothingMainComponent'));
const MenClothingMainComponent = React.lazy(() => import('./ads/fashionAndBeauty/MenClothingMainComponent'));
const WomenFootwearMainComponent = React.lazy(() => import('./ads/fashionAndBeauty/WomenFootwearMainComponent'));
const MenFootwearMainComponent = React.lazy(() => import('./ads/fashionAndBeauty/MenFootwareMainComponent'));
const WomenBagMainComponent = React.lazy(() => import('./ads/fashionAndBeauty/WomenBagMainComponent'));
const MenBagMainComponent = React.lazy(() => import('./ads/fashionAndBeauty/MenBagMainComponent'));
const WomenAccessMainComponent = React.lazy(() => import('./ads/fashionAndBeauty/WomenAccessMainComponent'));
const MenAccessMainComponent = React.lazy(() => import('./ads/fashionAndBeauty/MenAccessMainComponent'));
const MenWatchMainComponent = React.lazy(() => import('./ads/fashionAndBeauty/MenWatchMainComponent'));
const WomenWatchMainComponent = React.lazy(() => import('./ads/fashionAndBeauty/WomenWatchMainComponent'));
const VitaminSupplementMainComponent = React.lazy(() => import('./ads/fashionAndBeauty/VitaminSupplementMainComponent'));
const FragranceMainComponent = React.lazy(() => import('./ads/fashionAndBeauty/FragranceMainComponent'));
const HairStylingMainComponent = React.lazy(() => import('./ads/fashionAndBeauty/HairStylingMainComponent'));
const FashionHealthcareMainComponent = React.lazy(() => import('./ads/fashionAndBeauty/HealthcareMainComponent'));
const MobilityMainComponent = React.lazy(() => import('./ads/fashionAndBeauty/MobilityMainComponent'));
const ShavingHairMainComponent = React.lazy(() => import('./ads/fashionAndBeauty/ShavingHairMainComponent'));

const Pay = React.lazy(() => import('./core/Pay'));
const MembershipMainComponent = React.lazy(() => import('./user/MembershipMainComponent'));
const MembershipSuccessMainComponent = React.lazy(() => import('./user/MembershipSuccessComponent'));
const AdCreateSuccessMainComponent = React.lazy(() => import('./user/AdCreateSuccessMainComponent'));
const AdUpdateSuccessMainComponent = React.lazy(() => import('./user/AdUpdateSuccessMainComponent'));

const AdPaymentMainComponent = React.lazy(() => import('./user/AdPaymentmainComponent'));
const JobAdPaymentMainComponent = React.lazy(() => import('./user/JobAdPaymentMainComponent'));
const JobAd3DayPromoteMainComponent = React.lazy(() => import('./user/JobAd3DayPromoMainComponent'));
const JobAd7DayPromoteMainComponent = React.lazy(() => import('./user/JobAd7DayPromoMainComponent'));
const AdPaymentSuccessMainComponent = React.lazy(() => import('./user/AdPaymentSuccessMainComponent'));
const AdPromoteMainComponent = React.lazy(() => import('./user/AdPromoteMainComponent'));
const Ad3DayPromoteMainComponent = React.lazy(() => import('./user/Ad3DayPromotionMainComponent'));
const Ad7DayPromoteMainComponent = React.lazy(() => import('./user/Ad7DayPromotionMainComponent'));
const AdPromoSuccessMainComponent = React.lazy(() => import('./user/AdPromoSuccessMainComponent'));

const AllJobAdsByUserMainComponent = React.lazy(() => import('./user/AllJobAdsByUserMainComponent'));
const JobsMainComponent = React.lazy(() => import('./ads/jobs/JobsMainComponent'));
const SingleJobAd = React.lazy(() => import('./core/SingleJobAd'));
const ItSoftwareMainComponent = React.lazy(() => import('./ads/jobs/ItSoftwareMainComponent'));
const NetworkSystemMainComponent = React.lazy(() => import('./ads/jobs/NetworkSystemsMainComponent'));
const AccountingMainComponent = React.lazy(() => import('./ads/jobs/AccountingMainComponent'));
const HrMainComponent = React.lazy(() => import('./ads/jobs/HrMainComponent'));
const ApparelMainComponent = React.lazy(() => import('./ads/jobs/ApparelMainComponent'));
const HotelsMainComponent = React.lazy(() => import('./ads/jobs/HotelsMainComponent'));
const LogisticsMainComponent = React.lazy(() => import('./ads/jobs/LogisticsMainComponent'));
const SalesMainComponent = React.lazy(() => import('./ads/jobs/SalesMainComponent'));
const EngineeringMainComponent = React.lazy(() => import('./ads/jobs/EngineeringMainComponent'));
const TeachingMainComponent = React.lazy(() => import('./ads/jobs/TeachingMainComponent'));
const FashionMainComponent = React.lazy(() => import('./ads/jobs/FashionMainComponent'));
const BankingMainComponent = React.lazy(() => import('./ads/jobs/BankingMainComponent'));
const AutomobileMainComponent = React.lazy(() => import('./ads/jobs/AutomobileMainComponent'));
const HospitalMainComponent = React.lazy(() => import('./ads/jobs/HospitalMainComponent'));
const AgriMainComponent = React.lazy(() => import('./ads/jobs/AgriMainComponent'));
const ManufacturingMainComponent = React.lazy(() => import('./ads/jobs/ManufacturingMainComponent'));
const LegalMainComponent = React.lazy(() => import('./ads/jobs/LegalMainComponent'));
const SecurityMainComponent = React.lazy(() => import('./ads/jobs/SecurityMainComponent'));
const RandDMainComponent = React.lazy(() => import('./ads/jobs/R&DMainComponent'));
const AirlineMarineMainComponent = React.lazy(() => import('./ads/jobs/AirlineMarineMainComponent'));
const SportsMainComponent = React.lazy(() => import('./ads/jobs/SportsMainComponent'));
const SupervisionMainComponent = React.lazy(() => import('./ads/jobs/SupervisionMainComponent'));

const ElectronicAppliencesMainComponent = React.lazy(() => import('./ads/electronics/ElectronicAppliencesMainComponent'));
const ComputersMainComponent = React.lazy(() => import('./ads/electronics/ComputerMainComponent'));
const ComputerAccessoriesMainComponent = React.lazy(() => import('./ads/electronics/ComputerAccessoriesMainComponent'));
const AudioMainComponent = React.lazy(() => import('./ads/electronics/AudioMainComponent'));
const HomeAppliencesMainComponent = React.lazy(() => import('./ads/electronics/HomeAppliancesmainComponent'));
const GamingMainComponent = React.lazy(() => import('./ads/electronics/GamingMainComponent'));
const CamerasMainComponent = React.lazy(() => import('./ads/electronics/CamerasMainComponent'));
const AllAdsByUserMainComponent = React.lazy(() => import('./user/AllAdsByUserComponent'));
const AllAdsForAdminComponent = React.lazy(() => import('./user/AllAdsForAdminMainComponent'));
const AllJobAdsForAdminComponent = React.lazy(() => import('./user/AllJobAdsForAdminMainComponent'));

const UpdateAd = React.lazy(() => import('./user/updateAd'));
const UpdateJobAd=React.lazy(()=>import('./user/updateJobAd'))
const SingleAd = React.lazy(() => import('./core/SingleAd'));

const ServicesMainComponent = React.lazy(() => import('./ads/services/ServicesMainComponent'));
const ConstructionServicesMainComponent = React.lazy(() => import('./ads/services/ConstructionServicesMainComponent'));
const BusinessServicesMainComponent = React.lazy(() => import('./ads/services/BusinessServicesMainComponent'));
const ToursMainComponent = React.lazy(() => import('./ads/services/ToursMainComponent'));
const DomesticServicesMainComponent = React.lazy(() => import('./ads/services/DomesticServicesMainComponent'));
const AutomobileServicesMainComponent = React.lazy(() => import('./ads/services/AutomobileServicesMainComponent'));
const WeddingServicesMainComponent = React.lazy(() => import('./ads/services/WeddingServicesMainComponent'));
const EduServicesMainComponent = React.lazy(() => import('./ads/services/EduServicesMainComponent'));
const ItServicesMainComponent = React.lazy(() => import('./ads/services/ItServicesMainComponent'));
const MaterialServicesMainComponent = React.lazy(() => import('./ads/services/MaterialMainComponent'));
const PrintingServicesMainComponent = React.lazy(() => import('./ads/services/PrintingServicesMainComponent'));
const LogisticServicesMainComponent = React.lazy(() => import('./ads/services/LogisticsServicesMainComponent'));
const EventsServicesMainComponent = React.lazy(() => import('./ads/services/EventsServicesMainComponent'));
const SecurityServicesMainComponent = React.lazy(() => import('./ads/services/SecurityServicesMainComponent'));
const HrServicesMainComponent = React.lazy(() => import('./ads/services/HrServicesMainComponent'));
const ClinicalServicesMainComponent = React.lazy(() => import('./ads/services/ClinicalServicesMainComponent'));
const FinancialLegalServicesMainComponent = React.lazy(() => import('./ads/services/FinanceLegalServiceMainComponent'));

const AgricultureMainComponent = React.lazy(() => import('./ads/agriculture/AgricultureMainComponent'));
const PlantsMainComponent = React.lazy(() => import('./ads/agriculture/PlantsMainComponent'));
const SeedMainComponent = React.lazy(() => import('./ads/agriculture/SeedsMainComponent'));
const FertilizerMainComponent = React.lazy(() => import('./ads/agriculture/FertilizerMainComponent'));
const AgriMachineryMainComponent = React.lazy(() => import('./ads/agriculture/AgriMachineryMainComponent'));

const PetMainComponent = React.lazy(() => import('./ads/pets/PetMainComponent'));
const DogMainComponent = React.lazy(() => import('./ads/pets/DogMainComponent'));
const CatMainComponent = React.lazy(() => import('./ads/pets/CatMainComponent'));
const DogAccessoryMainComponent = React.lazy(() => import('./ads/pets/DogAccessoryMainComponent'));
const DogFoodMainComponent = React.lazy(() => import('./ads/pets/DogFoodMainComponent'));
const CatFoodMainComponent = React.lazy(() => import('./ads/pets/CatFoodMainComponent'));
const CatAccessoryMainComponent = React.lazy(() => import('./ads/pets/CatAccessoryMainComponent'));
const FishMainComponent = React.lazy(() => import('./ads/pets/FishMainComponent'));
const AquaMainComponent = React.lazy(() => import('./ads/pets/AquaMainComponent'));

const KidToyMainComponent = React.lazy(() => import('./ads/kids/KidsToysMainComponent'));
const BabyGearMainComponent = React.lazy(() => import('./ads/kids/BabyGearMainComponent'));
const BabyClothingMainComponent = React.lazy(() => import('./ads/kids/BabyClothingMainComponent'));
const BabyToysMainComponent = React.lazy(() => import('./ads/kids/BabyToysMainComponent'));

const HobbyEntertainmentMainComponent = React.lazy(() => import('./ads/hobbyEntertainment/HobbyEntertainmentMainComponent'));
const ToysMainComponent = React.lazy(() => import('./ads/hobbyEntertainment/ToysMainComponent'));
const BooksMainComponent = React.lazy(() => import('./ads/hobbyEntertainment/BooksMainComponent'));
const GamesMainComponent = React.lazy(() => import('./ads/hobbyEntertainment/GamesMainComponent'));
const MoviesMainComponent = React.lazy(() => import('./ads/hobbyEntertainment/MoviesMainComponent'));
const CollectibleArtMainComponent = React.lazy(() => import('./ads/hobbyEntertainment/CollectibleArtMainComponent'));

const UserShopMainComponent = React.lazy(() => import('./user/UserShopMainComponent'));

const About = React.lazy(() => import('./static/About'));
const RefundPolicy = React.lazy(() => import('./static/RefundPolicy'));

const PostingRules = React.lazy(() => import('./static/PostingRules'));
const Safetytips = React.lazy(() => import('./static/SafetyTips'));
const Terms = React.lazy(() => import('./static/Terms'));
const Privacy = React.lazy(() => import('./static/Privacy'));
const Faq = React.lazy(() => import('./static/Faq'));
const Contact = React.lazy(() => import('./static/Contact'));
const Advertise = React.lazy(() => import('./static/Advertise'));


// import App from './App';
// import Signup from './auth/Signup';
// import Signin from './auth/Signin';
// import Activate from './auth/Activate';
// import Private from './core/Private'
// import PrivateRoute from './auth/PrivateRoute'
// import AdminRoute from './auth/AdminRoute'
// import Admin from './core/Admin'
// import Forgot from './auth/Forgot'
// import Reset from './auth/Reset'
// import AddCategory from './admin/AddCategory'
// import AddAd from './user/addAd'
// import AddJobAd from './user/addJobAd';
// import NotFound from './core/NotFound';

// import AdChoiceComponent from './core/AdChoiceComponent'
// import QuerySearchComponent from './ads/QuerySearchComponent';
// import VehiclesMainComponent from './ads/vehicles/VehiclesMainComponent';
// import CarsMainComponent from './ads/vehicles/CarsMainComponent';
// import BikessMainComponent from './ads/vehicles/BikesMainComponent';
// import ThreeWheelMainComponent from './ads/vehicles/ThreeWheelMainComponent';
// import VansMainComponent from './ads/vehicles/VansMainComponent';
// import BusMainComponent from './ads/vehicles/BusMainComponent';


// import PropertiesMainComponent from './ads/properties/PropertiesMainComponent';
// import LandForSaleMainComponent from './ads/properties/LandForSaleMainComponent';
// import LandForRentMainComponent from './ads/properties/LandForRentMainComponent'
// import HouseForSaleMainComponent from './ads/properties/HouseForSaleMainComponent';
// import HouseForRentMainComponent from './ads/properties/HouseForRentMainComponent';
// import ApartmentForSaleMainComponent from './ads/properties/ApartmentForSaleMainComponent';
// import ApartmentForRentMainComponent from './ads/properties/ApartmentForRentMainComponent';
// import CommercialPropertyForSaleMainComponent from './ads/properties/CommercialPropertyForSale';
// import CommercialPropertyForRentMainComponent from './ads/properties/CommercialPropertyForRent';
// import RoomAnnexForRentMainComponent from './ads/properties/RoomAnnexForRentMainComponent';
// import HolidayPropertyForRentMainComponent from './ads/properties/HolidayPropertyForRentMainComponent';

// import BicycleMainComponent from './ads/vehicles/BicycleMainComponent';
// import WatercraftMainComponent from './ads/vehicles/WatercraftMainComponent';
// import VehicleRentalsMainComponent from './ads/vehicles/VehicleRentalsMainComponent';
// import WeddingCarsMainComponent from './ads/vehicles/WedingCarsMainComponent';
// import SparePartsMainComponent from './ads/spareparts/SparePartsMainComponent'
// import CarSparePartsMainComponent from './ads/spareparts/CarSparePartsMainComponent';
// import BikeSparePartMainComponent from './ads/spareparts/BikeSparePartsMainComponent';
// import VanSparePartsMainComponent from './ads/spareparts/VanSparePartsMainComponent';
// import ThreeWheelSparePartMainComponent from './ads/spareparts/ThreeWheelSparePartsMainComponent';

// import MobilesMainComponent from './ads/mobile/mobilesMainComponent';
// import TabsMainComponent from './ads/mobile/TabsMainComponent';
// import WearablesMainComponent from './ads/mobile/WearablesMainComponent';
// import MobileTabsWearablesMainComponent from './ads/mobile/MobilesTabsWearablesMainComponent';
// import MobileAccessoriesMainComponent from './ads/mobile/MobileAccessoriesMainComponent';

// import GemAndJwelleryMainComponent from './ads/gemsAndJwellery/GemAndJwelleryMainComponent';
// import GemsMainComponent from './ads/gemsAndJwellery/GemsMainComponent';
// import WomenJewelleryMainComponent from './ads/gemsAndJwellery/WomenJewelleryMainComponent';
// import MenJewelleryMainComponent from './ads/gemsAndJwellery/MenJewelleryMainComponent';

// import MusicInstrumentsMainComponent from './ads/musical_instruments/MusicInstrumentsMainComponent';
// import GuitarMainComponent from './ads/musical_instruments/GuitarMainComponent';
// import GuitarAccessoriesMainComponent from './ads/musical_instruments/GuitarAccessoriesMainComponent';
// import DrumMainComponent from './ads/musical_instruments/DrumsMainComponent';
// import UkuleleMainComponent from './ads/musical_instruments/UkuleleMainComponent';
// import MandolinMainComponent from './ads/musical_instruments/MandolinMainComponent'
// import IndianMainComponent from './ads/musical_instruments/IndianMainComponent'
// import ViolinMainComponent from './ads/musical_instruments/ViolinMainComponent';
// import WindMainComponent from './ads/musical_instruments/WindMainComponent';
// import AmpMainComponent from './ads/musical_instruments/AmpmainComponent';
// import EffectMainComponent from './ads/musical_instruments/EffectMainComponent';
// import KeyboardMainComponent from './ads/musical_instruments/KeyboardMainComponent';
// import StudioMainComponent from './ads/musical_instruments/StudioMainComponent';
// import PALiveSoundMainComponent from './ads/musical_instruments/PALiveSoundMainComponent';
// import DjMainComponent from './ads/musical_instruments/DjMainComponent';

// import HomeGardenMainComponent from './ads/homeGarden/HomeGardenMainComponent'
// import FurnitureMainComponent from './ads/homeGarden/FurnitureMainComponent';
// import GardenMainComponent from './ads/homeGarden/GardenMainComponent';
// import HomeDecorMainComponent from './ads/homeGarden/HomeDecorMainComponent';
// import KitchenMainComponent from './ads/homeGarden/KitchenMainComponent';
// import LampsMainComponent from './ads/homeGarden/LampsMainComponent';
// import HouseHoldCleaningMainComponent from './ads/homeGarden/HouseHoldCleaningMainComponent';
// import HomeOrganizationMainComponent from './ads/homeGarden/HomeOrganizationMainComponent';
// import BeddingMainComponent from './ads/homeGarden/BeddingMainComponent';
// import RugsMainComponent from './ads/homeGarden/RugsMainComponent';

// import EarthMovingMainComponent from './ads/industryMachinery/EarthMovingMainComponent';
// import IndustryMachineryMainComponent from './ads/industryMachinery/IndustryMachinerymainComponent';
// import MetalindustryMainComponent from './ads/industryMachinery/MetalIndustryMainComponent';
// import PowertoolMainComponent from './ads/industryMachinery/PowertoolsMainComponent';
// import ElectricindustryMainComponent from './ads/industryMachinery/ElectricindustryMainComponent';
// import IndustrialAutomationMainComponent from './ads/industryMachinery/IndustrialAutomationMainComponent';
// import HealthcareMainComponent from './ads/industryMachinery/HealthcareMainComponent';
// import IndustryCoolingHeatingMainComponent from './ads/industryMachinery/IndustryCoolingHeatingMainComponent';
// import PowerGenerationMainComponent from './ads/industryMachinery/PowerGenerationMainComponent';
// import PrintingIndustryMainComponent from './ads/industryMachinery/PrintingIndustryMainComponent';
// import MaterialHandlingMainComponent from './ads/industryMachinery/MaterialHandlingMainComponent';
// import FoodProcessingMainComponent from './ads/industryMachinery/FoodProcessingMainComponent';
// import TextileIndustryMainComponent from './ads/industryMachinery/TextileIndustryMainComponent';

// import SportsFitnessMainComponent from './ads/sports_fitness/SportsFitnessMainCoponent';
// import FitnessGymMainComponent from './ads/sports_fitness/FitnessGymMainComponent';
// import SupplementMainComponent from './ads/sports_fitness/SupplementMainComponent';
// import TeamSportsMainComponent from './ads/sports_fitness/TeamSportsMainComponent';
// import IndoorSportsMainComponent from './ads/sports_fitness/IndoorSportsMainComponent';
// import TennisSportsMainComponent from './ads/sports_fitness/TennisSportsMainComponent';
// import CyclingMainComponent from './ads/sports_fitness/CyclingMainComponent';
// import UrbanSportsMainComponent from './ads/sports_fitness/UrbanSportsMainComponent';
// import CampingMainComponent from './ads/sports_fitness/CampingMainComponent';

// import FashionAndBeautyMainComponent from './ads/fashionAndBeauty/FashionAndBeautyMainComponent';
// import WomenClothingMainComponent from './ads/fashionAndBeauty/WomenClothingMainComponent'
// import MenClothingMainComponent from './ads/fashionAndBeauty/MenClothingMainComponent';
// import WomenFootwearMainComponent from './ads/fashionAndBeauty/WomenFootwearMainComponent';
// import MenFootwearMainComponent from './ads/fashionAndBeauty/MenFootwareMainComponent';
// import WomenBagMainComponent from './ads/fashionAndBeauty/WomenBagMainComponent';
// import MenBagMainComponent from './ads/fashionAndBeauty/MenBagMainComponent';
// import WomenAccessMainComponent from './ads/fashionAndBeauty/WomenAccessMainComponent';
// import MenAccessMainComponent from './ads/fashionAndBeauty/MenAccessMainComponent';
// import MenWatchMainComponent from './ads/fashionAndBeauty/MenWatchMainComponent';
// import WomenWatchMainComponent from './ads/fashionAndBeauty/WomenWatchMainComponent';
// import VitaminSupplementMainComponent from './ads/fashionAndBeauty/VitaminSupplementMainComponent';
// import FragranceMainComponent from './ads/fashionAndBeauty/FragranceMainComponent';
// import HairStylingMainComponent from './ads/fashionAndBeauty/HairStylingMainComponent';
// import FashionHealthcareMainComponent from './ads/fashionAndBeauty/HealthcareMainComponent';
// import MobilityMainComponent from './ads/fashionAndBeauty/MobilityMainComponent';
// import ShavingHairMainComponent from './ads/fashionAndBeauty/ShavingHairMainComponent';

// import Pay from './core/Pay';
// import MembershipMainComponent from './user/MembershipMainComponent'
// import MembershipSuccessMainComponent from './user/MembershipSuccessComponent';
// import AdPaymentMainComponent from './user/AdPaymentmainComponent';
// import JobAdPaymentMainComponent from './user/JobAdPaymentMainComponent';
// import JobAd3DayPromoteMainComponent from './user/JobAd3DayPromoMainComponent';
// import JobAd7DayPromoteMainComponent from './user/JobAd7DayPromoMainComponent';
// import AdPaymentSuccessMainComponent from './user/AdPaymentSuccessMainComponent';
// import AdPromoteMainComponent from './user/AdPromoteMainComponent';
// import Ad3DayPromoteMainComponent from './user/Ad3DayPromotionMainComponent';
// import Ad7DayPromoteMainComponent from './user/Ad7DayPromotionMainComponent';
// import AdPromoSuccessMainComponent from './user/AdPromoSuccessMainComponent';

// import AllJobAdsByUserMainComponent from './user/AllJobAdsByUserMainComponent';
// import JobsMainComponent from './ads/jobs/JobsMainComponent'
// import SingleJobAd from './core/SingleJobAd';
// import ItSoftwareMainComponent from './ads/jobs/ItSoftwareMainComponent';
// import NetworkSystemMainComponent from './ads/jobs/NetworkSystemsMainComponent';
// import AccountingMainComponent from './ads/jobs/AccountingMainComponent';
// import HrMainComponent from './ads/jobs/HrMainComponent';
// import ApparelMainComponent from './ads/jobs/ApparelMainComponent';
// import HotelsMainComponent from './ads/jobs/HotelsMainComponent';
// import LogisticsMainComponent from './ads/jobs/LogisticsMainComponent';
// import SalesMainComponent from './ads/jobs/SalesMainComponent';
// import EngineeringMainComponent from './ads/jobs/EngineeringMainComponent';
// import TeachingMainComponent from './ads/jobs/TeachingMainComponent';
// import FashionMainComponent from './ads/jobs/FashionMainComponent';
// import BankingMainComponent from './ads/jobs/BankingMainComponent';
// import SupervisionMainComponent from './ads/jobs/SupervisionMainComponent';
// import AutomobileMainComponent from './ads/jobs/AutomobileMainComponent';
// import HospitalMainComponent from './ads/jobs/HospitalMainComponent';
// import AgriMainComponent from './ads/jobs/AgriMainComponent';
// import ManufacturingMainComponent from './ads/jobs/ManufacturingMainComponent';
// import LegalMainComponent from './ads/jobs/LegalMainComponent';
// import SecurityMainComponent from './ads/jobs/SecurityMainComponent';
// import RandDMainComponent from './ads/jobs/R&DMainComponent';
// import AirlineMarineMainComponent from './ads/jobs/AirlineMarineMainComponent';
// import SportsMainComponent from './ads/jobs/SportsMainComponent';

// import ElectronicAppliencesMainComponent from './ads/electronics/ElectronicAppliencesMainComponent';
// import ComputersMainComponent from './ads/electronics/ComputerMainComponent';
// import ComputerAccessoriesMainComponent from './ads/electronics/ComputerAccessoriesMainComponent';
// import AudioMainComponent from './ads/electronics/AudioMainComponent';
// import HomeAppliencesMainComponent from './ads/electronics/HomeAppliancesmainComponent';
// import GamingMainComponent from './ads/electronics/GamingMainComponent';
// import CamerasMainComponent from './ads/electronics/CamerasMainComponent';
// import AllAdsByUserMainComponent from './user/AllAdsByUserComponent';
// import AllAdsForAdminComponent from './user/AllAdsForAdminMainComponent';
// import UpdateAd from './user/updateAd';
// import SingleAd from './core/SingleAd';

// import ServicesMainComponent from './ads/services/ServicesMainComponent';
// import ConstructionServicesMainComponent from './ads/services/ConstructionServicesMainComponent';
// import BusinessServicesMainComponent from './ads/services/BusinessServicesMainComponent';
// import ToursMainComponent from './ads/services/ToursMainComponent';
// import DomesticServicesMainComponent from './ads/services/DomesticServicesMainComponent';
// import AutomobileServicesMainComponent from './ads/services/AutomobileServicesMainComponent';
// import WeddingServicesMainComponent from './ads/services/WeddingServicesMainComponent';
// import EduServicesMainComponent from './ads/services/EduServicesMainComponent';
// import ItServicesMainComponent from './ads/services/ItServicesMainComponent';
// import MaterialServicesMainComponent from './ads/services/MaterialMainComponent';
// import PrintingServicesMainComponent from './ads/services/PrintingServicesMainComponent';
// import LogisticServicesMainComponent from './ads/services/LogisticsServicesMainComponent';
// import EventsServicesMainComponent from './ads/services/EventsServicesMainComponent';
// import SecurityServicesMainComponent from './ads/services/SecurityServicesMainComponent';
// import HrServicesMainComponent from './ads/services/HrServicesMainComponent';
// import ClinicalServicesMainComponent from './ads/services/ClinicalServicesMainComponent';
// import FinancialLegalServicesMainComponent from './ads/services/FinanceLegalServiceMainComponent';

// import AgricultureMainComponent from './ads/agriculture/AgricultureMainComponent';
// import PlantsMainComponent from './ads/agriculture/PlantsMainComponent';
// import SeedMainComponent from './ads/agriculture/SeedsMainComponent';
// import FertilizerMainComponent from './ads/agriculture/FertilizerMainComponent';
// import AgriMachineryMainComponent from './ads/agriculture/AgriMachineryMainComponent';

// import PetMainComponent from './ads/pets/PetMainComponent';
// import DogMainComponent from './ads/pets/DogMainComponent';
// import CatMainComponent from './ads/pets/CatMainComponent';
// import DogAccessoryMainComponent from './ads/pets/DogAccessoryMainComponent';
// import DogFoodMainComponent from './ads/pets/DogFoodMainComponent';
// import CatFoodMainComponent from './ads/pets/CatFoodMainComponent';
// import CatAccessoryMainComponent from './ads/pets/CatAccessoryMainComponent';
// import FishMainComponent from './ads/pets/FishMainComponent';
// import AquaMainComponent from './ads/pets/AquaMainComponent';

// import KidToyMainComponent from './ads/kids/KidsToysMainComponent';
// import BabyGearMainComponent from './ads/kids/BabyGearMainComponent';
// import BabyClothingMainComponent from './ads/kids/BabyClothingMainComponent';
// import BabyToysMainComponent from './ads/kids/BabyToysMainComponent';

// import HobbyEntertainmentMainComponent from './ads/hobbyEntertainment/HobbyEntertainmentMainComponent';
// import ToysMainComponent from './ads/hobbyEntertainment/ToysMainComponent';
// import BooksMainComponent from './ads/hobbyEntertainment/BooksMainComponent';
// import GamesMainComponent from './ads/hobbyEntertainment/GamesMainComponent';
// import MoviesMainComponent from './ads/hobbyEntertainment/MoviesMainComponent';

// import UserShopMainComponent from './user/UserShopMainComponent';

// import About from './static/About';
// import PostingRules from './static/PostingRules';
// import Safetytips from './static/SafetyTips'
// import Terms from './static/Terms';
// import Privacy from './static/Privacy';
// import Faq from './static/Faq';


const Routes = () => {
    return (
        <Suspense fallback={<LoadingOverlay
            active={true}
            spinner
            text='loading content!'
            styles={{
                wrapper: {
                width: '400px',
                height: '400px',
                overflow: true ? 'hidden' : 'scroll'
                }
            }}
            >
            </LoadingOverlay>}>
        <BrowserRouter>
            <Switch>
            
                <Route path="/" exact component={App} />
                <Route path="/ad/post" exact component={AdChoiceComponent} />
                <Route path="/signup" exact component={Signup} />
                <Route path="/signup/success" exact component={SignupEmailSent} />
                <Route path="/signin" exact component={Signin} />
                <Route path="/auth/activate/:token" exact component={Activate} />
                <PrivateRoute path="/private" exact component={Private} />
                <PrivateRoute path="/update/user" exact component={UpdateUser} />
                <PrivateRoute path="/ad/create" exact component={AddAd} />
                <PrivateRoute path="/pay" exact component={Pay} />
                <PrivateRoute path="/get/membership" exact component={MembershipMainComponent} />
                <PrivateRoute path="/success/membership" exact component={MembershipSuccessMainComponent} />
                <PrivateRoute path="/success/ad/create" exact component={AdCreateSuccessMainComponent} />
                <PrivateRoute path="/success/ad/update" exact component={AdUpdateSuccessMainComponent} />
                <PrivateRoute path="/ad/payment" exact component={AdPaymentMainComponent} />
                <PrivateRoute path="/jobAd/payment" exact component={JobAdPaymentMainComponent} />
                <PrivateRoute path="/jobAd/promote/level1" exact component={JobAd3DayPromoteMainComponent} />
                <PrivateRoute path="/jobAd/promote/level2" exact component={JobAd7DayPromoteMainComponent} />
                <PrivateRoute path="/ad/payment/success" exact component={AdPaymentSuccessMainComponent} />
                <PrivateRoute path="/ad/promote/level1" exact component={Ad3DayPromoteMainComponent} />
                <PrivateRoute path="/ad/promote/level2" exact component={Ad7DayPromoteMainComponent} />
                <PrivateRoute path="/ad/promoPayment/success" exact component={AdPromoSuccessMainComponent} />

                <PrivateRoute path="/job/create" exact component={AddJobAd} />
                <PrivateRoute path="/ad/update/:adId" exact component={UpdateAd} />
                <PrivateRoute path="/jobAd/update/:adId" exact component={UpdateJobAd} />
                <AdminRoute path="/admin" exact component={Admin} />
                <AdminRoute path="/create/category" exact component={AddCategory} />
                <AdminRoute path="/scrape/ads" exact component={CarScraper} />
                <AdminRoute path="/manage/ads/for/admin" exact component={AllAdsForAdminComponent} />
                <AdminRoute path="/manage/jobAds/for/admin" exact component={AllJobAdsForAdminComponent} />

                <Route path="/auth/password/forgot" exact component={Forgot} />
                <Route path="/auth/password/reset/:token" exact component={Reset} />

                <Route path="/ad/:adId" exact component={SingleAd}/>
                <Route path="/vehicles" exact component={VehiclesMainComponent}/>
                <Route path="/vehicles/cars" exact component={CarsMainComponent}/>
                <Route path="/vehicles/bikes" exact component={BikessMainComponent}/>
                <Route path="/vehicles/threeWheelers" exact component={ThreeWheelMainComponent}/>
                <Route path="/vehicles/vans" exact component={VansMainComponent}/>
                <Route path="/vehicles/buses" exact component={BusMainComponent}/>
                <Route path="/vehicles/bicycles" exact component={BicycleMainComponent}/>
                <Route path="/vehicles/watercrafts" exact component={WatercraftMainComponent}/>
                <Route path="/vehicles/rentals" exact component={VehicleRentalsMainComponent}/>
                <Route path="/vehicles/wedding_cars" exact component={WeddingCarsMainComponent}/>
                <Route path="/spare_parts" exact component={SparePartsMainComponent}/>
                <Route path="/spare_parts/cars" exact component={CarSparePartsMainComponent}/>
                <Route path="/spare_parts/bikes" exact component={BikeSparePartMainComponent}/>
                <Route path="/spare_parts/vans" exact component={VanSparePartsMainComponent}/>
                <Route path="/spare_parts/three_wheels" exact component={ThreeWheelSparePartMainComponent}/>



                <PrivateRoute path="/manage/ads" exact component={AllAdsByUserMainComponent}/>
                <PrivateRoute path="/manage/jobAds" exact component={AllJobAdsByUserMainComponent}/>


                <Route path="/ads/search"  component={QuerySearchComponent}/>
                <Route path="/jobAd/:adId" exact component={SingleJobAd}/>

                <Route path="/properties" exact component={PropertiesMainComponent}/>
                <Route path="/properties/lands_for_sale" exact component={LandForSaleMainComponent}/>
                <Route path="/properties/lands_for_rent" exact component={LandForRentMainComponent}/>
                <Route path="/properties/houses_for_sale" exact component={HouseForSaleMainComponent}/>
                <Route path="/properties/houses_for_rent" exact component={HouseForRentMainComponent}/>
                <Route path="/properties/apartment_for_sale" exact component={ApartmentForSaleMainComponent}/>
                <Route path="/properties/apartment_for_rent" exact component={ApartmentForRentMainComponent}/>
                <Route path="/properties/commercial_property_for_sale" exact component={CommercialPropertyForSaleMainComponent}/>
                <Route path="/properties/commercial_property_for_rent" exact component={CommercialPropertyForRentMainComponent}/>
                <Route path="/properties/rooms_annex_for_rent" exact component={RoomAnnexForRentMainComponent}/>
                <Route path="/properties/holiday_property_for_rent" exact component={HolidayPropertyForRentMainComponent}/>






                <Route path="/mobiles_tabs_wearables" exact component={MobileTabsWearablesMainComponent}/>
                <Route path="/mobiles_tabs_wearables/mobiles" exact component={MobilesMainComponent}/>
                <Route path="/mobiles_tabs_wearables/tablets" exact component={TabsMainComponent}/>
                <Route path="/mobiles_tabs_wearables/mobile_accessories" exact component={MobileAccessoriesMainComponent}/>
                <Route path="/mobiles_tabs_wearables/wearables" exact component={WearablesMainComponent}/>

                <Route path="/electronics" exact component={ElectronicAppliencesMainComponent}/>
                <Route path="/electronics/computers" exact component={ComputersMainComponent}/>
                <Route path="/electronics/computer_accessories" exact component={ComputerAccessoriesMainComponent}/>
                <Route path="/electronics/gaming" exact component={GamingMainComponent}/>
                <Route path="/electronics/cameras" exact component={CamerasMainComponent}/>
                <Route path="/electronics/audio_mp3" exact component={AudioMainComponent}/>
                <Route path="/electronics/home_appliances" exact component={HomeAppliencesMainComponent}/>

                <Route path="/gems_and_jewellery" exact component={GemAndJwelleryMainComponent}/>
                <Route path="/gems_and_jewellery/gems" exact component={GemsMainComponent}/>
                <Route path="/gems_and_jewellery/women_jewellery" exact component={WomenJewelleryMainComponent}/>
                <Route path="/gems_and_jewellery/men_jewellery" exact component={MenJewelleryMainComponent}/>



                <Route path="/home_and_garden" exact component={HomeGardenMainComponent}/>
                <Route path="/home_and_garden/furniture" exact component={FurnitureMainComponent}/>
                <Route path="/home_and_garden/garden_and_outdoor" exact component={GardenMainComponent}/>
                <Route path="/home_and_garden/homedecor" exact component={HomeDecorMainComponent}/>
                <Route path="/home_and_garden/kitchen_dining" exact component={KitchenMainComponent}/>
                <Route path="/home_and_garden/lamps" exact component={LampsMainComponent}/>
                <Route path="/home_and_garden/household_cleaning" exact component={HouseHoldCleaningMainComponent}/>
                <Route path="/home_and_garden/home_organization" exact component={HomeOrganizationMainComponent}/>
                <Route path="/home_and_garden/bedding" exact component={BeddingMainComponent}/>
                <Route path="/home_and_garden/rugs_carpets" exact component={RugsMainComponent}/>





                <Route path="/musical_instruments" exact component={MusicInstrumentsMainComponent}/>
                <Route path="/musical_instruments/guitars" exact component={GuitarMainComponent}/>
                <Route path="/musical_instruments/guitar_accessories" exact component={GuitarAccessoriesMainComponent}/>
                <Route path="/musical_instruments/drums" exact component={DrumMainComponent}/>
                <Route path="/musical_instruments/ukulele" exact component={UkuleleMainComponent}/>
                <Route path="/musical_instruments/mandolin" exact component={MandolinMainComponent}/>
                <Route path="/musical_instruments/indian" exact component={IndianMainComponent}/>
                <Route path="/musical_instruments/violins" exact component={ViolinMainComponent}/>
                <Route path="/musical_instruments/wind_instruments" exact component={WindMainComponent}/>
                <Route path="/musical_instruments/amps" exact component={AmpMainComponent}/>
                <Route path="/musical_instruments/effect_units" exact component={EffectMainComponent}/>
                <Route path="/musical_instruments/keyboards" exact component={KeyboardMainComponent}/>
                <Route path="/musical_instruments/studio_recording" exact component={StudioMainComponent}/>
                <Route path="/musical_instruments/PA_live_sound" exact component={PALiveSoundMainComponent}/>
                <Route path="/musical_instruments/dj" exact component={DjMainComponent}/>

                <Route path="/industrial_machinery" exact component={IndustryMachineryMainComponent}/>
                <Route path="/industrial_machinery/earth_moving" exact component={EarthMovingMainComponent}/>
                <Route path="/industrial_machinery/metal_industry" exact component={MetalindustryMainComponent}/>
                <Route path="/industrial_machinery/power_tools" exact component={PowertoolMainComponent}/>
                <Route path="/industrial_machinery/electric_industry" exact component={ElectricindustryMainComponent}/>
                <Route path="/industrial_machinery/industrial_automation" exact component={IndustrialAutomationMainComponent}/>
                <Route path="/industrial_machinery/healthcare_industry" exact component={HealthcareMainComponent}/>
                <Route path="/industrial_machinery/industrial_cooling_and_heating" exact component={IndustryCoolingHeatingMainComponent}/>
                <Route path="/industrial_machinery/power_generation" exact component={PowerGenerationMainComponent}/>
                <Route path="/industrial_machinery/printing_industry" exact component={PrintingIndustryMainComponent}/>
                <Route path="/industrial_machinery/material_handling" exact component={MaterialHandlingMainComponent}/>
                <Route path="/industrial_machinery/food_processing" exact component={FoodProcessingMainComponent}/>
                <Route path="/industrial_machinery/textile_industry" exact component={TextileIndustryMainComponent}/>

                <Route path="/sports_fitness" exact component={SportsFitnessMainComponent}/>
                <Route path="/sports_fitness/fitness_gym" exact component={FitnessGymMainComponent}/>
                <Route path="/sports_fitness/supplements" exact component={SupplementMainComponent}/>
                <Route path="/sports_fitness/team_sports" exact component={TeamSportsMainComponent}/>
                <Route path="/sports_fitness/indoor_sports" exact component={IndoorSportsMainComponent}/>
                <Route path="/sports_fitness/tennis_sports" exact component={TennisSportsMainComponent}/>
                <Route path="/sports_fitness/cycling_sports" exact component={CyclingMainComponent}/>
                <Route path="/sports_fitness/urban_sports" exact component={UrbanSportsMainComponent}/>
                <Route path="/sports_fitness/camping" exact component={CampingMainComponent}/>

                <Route path="/fashion_and_beauty" exact component={FashionAndBeautyMainComponent}/>
                <Route path="/fashion_and_beauty/women_clothing" exact component={WomenClothingMainComponent}/>
                <Route path="/fashion_and_beauty/men_clothing" exact component={MenClothingMainComponent}/>
                <Route path="/fashion_and_beauty/women_footware" exact component={WomenFootwearMainComponent}/>
                <Route path="/fashion_and_beauty/men_footware" exact component={MenFootwearMainComponent}/>
                <Route path="/fashion_and_beauty/women_bags_wallets" exact component={WomenBagMainComponent}/>
                <Route path="/fashion_and_beauty/men_bags_wallets" exact component={MenBagMainComponent}/>
                <Route path="/fashion_and_beauty/women_accessories" exact component={WomenAccessMainComponent}/>
                <Route path="/fashion_and_beauty/men_accessories" exact component={MenAccessMainComponent}/>
                <Route path="/fashion_and_beauty/men_watches" exact component={MenWatchMainComponent}/>
                <Route path="/fashion_and_beauty/women_watches" exact component={WomenWatchMainComponent}/>
                <Route path="/fashion_and_beauty/vitamins_supplements" exact component={VitaminSupplementMainComponent}/>
                <Route path="/fashion_and_beauty/fragrances" exact component={FragranceMainComponent}/>
                <Route path="/fashion_and_beauty/hair_styling" exact component={HairStylingMainComponent}/>
                <Route path="/fashion_and_beauty/healthcare" exact component={FashionHealthcareMainComponent}/>
                <Route path="/fashion_and_beauty/healthcare_mobility" exact component={MobilityMainComponent}/>
                <Route path="/fashion_and_beauty/shaving_hair_removal" exact component={ShavingHairMainComponent}/>


                <Route path="/services" exact component={ServicesMainComponent}/>
                <Route path="/services/construction_services" exact component={ConstructionServicesMainComponent}/>
                <Route path="/services/business_services" exact component={BusinessServicesMainComponent}/>
                <Route path="/services/tour_visa_services" exact component={ToursMainComponent}/>
                <Route path="/services/domestic_services" exact component={DomesticServicesMainComponent}/>
                <Route path="/services/auto_services" exact component={AutomobileServicesMainComponent}/>
                <Route path="/services/wedding_services" exact component={WeddingServicesMainComponent}/>
                <Route path="/services/educational_services" exact component={EduServicesMainComponent}/>
                <Route path="/services/IT_services" exact component={ItServicesMainComponent}/>
                <Route path="/services/material_services" exact component={MaterialServicesMainComponent}/>
                <Route path="/services/printing_services" exact component={PrintingServicesMainComponent}/>
                <Route path="/services/logistic_and_transport_services" exact component={LogisticServicesMainComponent}/>
                <Route path="/services/events_services" exact component={EventsServicesMainComponent}/>
                <Route path="/services/security_services" exact component={SecurityServicesMainComponent}/>
                <Route path="/services/hr_services" exact component={HrServicesMainComponent}/>
                <Route path="/services/clinical_services" exact component={ClinicalServicesMainComponent}/>
                <Route path="/services/financial_and_legal_services" exact component={FinancialLegalServicesMainComponent}/>

                <Route path="/agriculture" exact component={AgricultureMainComponent}/>
                <Route path="/agriculture/plants" exact component={PlantsMainComponent}/>
                <Route path="/agriculture/seeds" exact component={SeedMainComponent}/>
                <Route path="/agriculture/fertilizers" exact component={FertilizerMainComponent}/>
                <Route path="/agriculture/farming_machinery_tools" exact component={AgriMachineryMainComponent}/>

                <Route path="/animals" exact component={PetMainComponent}/>
                <Route path="/animals/dogs" exact component={DogMainComponent}/>
                <Route path="/animals/cats" exact component={CatMainComponent}/>
                <Route path="/animals/dog_accessory" exact component={DogAccessoryMainComponent}/>
                <Route path="/animals/dog_supplements_food" exact component={DogFoodMainComponent}/>
                <Route path="/animals/cat_supplements_food" exact component={CatFoodMainComponent}/>
                <Route path="/animals/cat_accessory" exact component={CatAccessoryMainComponent}/>
                <Route path="/animals/fish" exact component={FishMainComponent}/>
                <Route path="/animals/aquarium_supply" exact component={AquaMainComponent}/>

                <Route path="/kids_and_toys" exact component={KidToyMainComponent}/>
                <Route path="/kids_and_toys/baby_gear" exact component={BabyGearMainComponent}/>
                <Route path="/kids_and_toys/baby_clothing" exact component={BabyClothingMainComponent}/>
                <Route path="/kids_and_toys/toys" exact component={BabyToysMainComponent}/>

                <Route path="/Hobby_entertainment" exact component={HobbyEntertainmentMainComponent}/>
                <Route path="/Hobby_entertainment/toys" exact component={ToysMainComponent}/>
                <Route path="/Hobby_entertainment/books" exact component={BooksMainComponent}/>
                <Route path="/Hobby_entertainment/games" exact component={GamesMainComponent}/>
                <Route path="/Hobby_entertainment/movies" exact component={MoviesMainComponent}/>
                <Route path="/Hobby_entertainment/collectibles_art" exact component={CollectibleArtMainComponent}/>

                <Route path="/user/shop/:userId" exact component={UserShopMainComponent}/>


                <Route path="/jobs" exact component={JobsMainComponent}/>
                <Route path="/jobs/IT_Software" exact component={ItSoftwareMainComponent}/>
                <Route path="/jobs/network_systems" exact component={NetworkSystemMainComponent}/>
                <Route path="/jobs/accounting_finance" exact component={AccountingMainComponent}/>
                <Route path="/jobs/hr_training" exact component={HrMainComponent}/>
                <Route path="/jobs/apperal_clothing" exact component={ApparelMainComponent}/>
                <Route path="/jobs/hotels_restaurants" exact component={HotelsMainComponent}/>
                <Route path="/jobs/logistics_warehouse" exact component={LogisticsMainComponent}/>
                <Route path="/jobs/sales_marketing" exact component={SalesMainComponent}/>
                <Route path="/jobs/engineering_architecturing" exact component={EngineeringMainComponent}/>
                <Route path="/jobs/teaching_academic" exact component={TeachingMainComponent}/>
                <Route path="/jobs/fashion_beauty" exact component={FashionMainComponent}/>
                <Route path="/jobs/banking_insuarance" exact component={BankingMainComponent}/>
                <Route path="/jobs/quality_control" exact component={SupervisionMainComponent}/>
                <Route path="/jobs/automobile" exact component={AutomobileMainComponent}/>
                <Route path="/jobs/hospital_nursing" exact component={HospitalMainComponent}/>
                <Route path="/jobs/agri_farming" exact component={AgriMainComponent}/>
                <Route path="/jobs/manufacturing_production" exact component={ManufacturingMainComponent}/>
                <Route path="/jobs/legal_law" exact component={LegalMainComponent}/>
                <Route path="/jobs/security" exact component={SecurityMainComponent}/>
                <Route path="/jobs/r&d_science" exact component={RandDMainComponent}/>
                <Route path="/jobs/airline_marine" exact component={AirlineMarineMainComponent}/>
                <Route path="/jobs/sports_fitness" exact component={SportsMainComponent}/>




                <Route path="/about" exact component={About}/>
                <Route path="/posting_rules" exact component={PostingRules}/>
                <Route path="/terms" exact component={Terms}/>
                <Route path="/safety" exact component={Safetytips}/>
                <Route path="/privacy_policy" exact component={Privacy}/>
                <Route path="/faq" exact component={Faq}/>
                <Route path="/contact" exact component={Contact}/>
                <Route path="/advertise" exact component={Advertise}/>
                <Route path="/paid_services" exact component={RefundPolicy}/>




                <Route component={NotFound}></Route>
            </Switch>
        </BrowserRouter>
        </Suspense>
    );
};

export default Routes;
